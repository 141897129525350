@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.historydkv {
  > ul {
    margin: 0 auto;
    width: 700px;
    > li:last-child {
      margin-bottom: 3rem;
    }

    @include breakpoint(desktop) {
      width: auto;
      margin: 0 2rem;
    }
  }
}
