@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';
@import '../../_styles/buttons';

.nav {
  height: $navbar-height;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 13.7rem;
  background-color: $primary-color;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint(monitor) {
    padding: 0 4rem;
  }
  @include breakpoint(desktop) {
    padding: 0 2rem;
  }
  @include breakpoint(tablet) {
    padding: 0 1rem;
  }

  &.navbar-top {
    background-color: rgba(0, 0, 0, 0);

    .icon {
      color: $primary-color;
    }
  }

  span {
    text-transform: uppercase;
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > *:not(:first-child) {
      margin-left: 3.2rem;
    }
  }

  .active {
    padding: 2rem 0;
    text-decoration: underline;
    color: $white;
  }
}

a {
  font-weight: bold;
  color: $white;
  font-size: 1.4rem;
  text-transform: uppercase;
  line-height: 1.8rem;
}

.flag {
  @include unstyled-button;
  background-color: transparent;
}

.mobile-menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  top: $navbar-height;
  z-index: 2;
  left: 0;
  background-color: $secondary-color;
  overflow-y: auto;
  z-index: 5;
  height: 0;
  width: 100vw;
  transition: height 300ms ease-in-out;

  a {
    width: 90%;
    padding: 2rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .flag {
    position: absolute;

    &:first-of-type {
      bottom: 2rem;
      right: 8rem;
    }

    &:nth-of-type(2) {
      bottom: 2rem;
      right: 2rem;
    }
  }
}

.open {
  height: calc(100vh - $navbar-height);
}
