@import '../../_styles/breakpoints';
@import '../../_styles/colors';
@import '../../_styles/constants';
.form {
  width: 70rem;
  margin: 8rem auto;
  margin-bottom: 10rem;

  @include breakpoint(desktop) {
    width: auto;
    margin: 0 2rem;
    margin-bottom: 5rem;
  }

  > h3 {
    text-align: center;
    color: $primary-color;
  }

  > ul {
    display: flex;
    justify-content: space-between;
    margin: 4rem 0;
    align-items: center;
    position: relative;

    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;

      > button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $grey;
        border: 0.4rem solid $white;
        border-radius: 5rem;
        text-align: center;
        font-size: 1.6rem;
        width: 3.6rem;
        height: 3.6rem;
        color: $white;

        .icon {
          svg {
            path {
              fill: none;
            }
          }
        }
      }

      .step__active {
        background-color: $blue;
      }

      .step__active__text {
        color: $blue;
      }

      > span {
        font-size: 1.4rem;
        font-weight: 500;
      }
    }
  }

  > ul:after {
    content: '';
    border-bottom: 0.3rem solid $grey-light;
    position: absolute;
    transform: translateY(-50%);
    left: 2rem;
    top: 33%;
    width: 93%;

    @include breakpoint(desktop) {
      width: 90%;
    }
  }

  &__wrapper {
    background: rgba(0, 0, 0, 0.03);
    box-shadow: 1px 1px 4px $form-box-shadow;
    border-radius: 1rem;
    padding: 3rem;

    h4 {
      color: $blue;
      text-align: center;
      margin-bottom: 2rem;
    }

    h5 {
      color: $blue;
      margin-bottom: 1rem;
    }

    p {
      color: $form-secondary-color;
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }

    label {
      color: $form-primary-color;
      font-size: medium;
    }

    .error {
      color: $primary-color;
      font-size: small;
      margin-top: 1rem
    }

    hr {
      color: $form-bar;
      margin-bottom: 3rem;
    }
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    .button {
      border: 3px solid $primary-color;
      box-shadow: 1px 1px 3px $form-box-shadow;
      border-radius: 5px;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      
      .icon__right {
        margin-left: 1rem;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .icon__left {
        margin-right: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;

    &__button {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0.5rem;

      @include breakpoint(phone) {
        width: 50%;
        font-size: 1.6rem;
      }
      @include breakpoint(mini-phone) {
        width: 90%;
        font-size: 1.8rem;
      }
    }
    @include breakpoint(mini-phone) {
      flex-direction: column;
    }
  }


}

.icon__right {
  @include breakpoint(mini-phone) {
    margin-left: 0.6rem !important;
    width: 55%;
  }
}

.step_indicator_label {
  @include breakpoint(phone) {
    display: none;
  }
}

input[type="date"] {
  font-family: $font-family;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.input-fields-bus-phone-container {
  gap: 3rem;
  display: flex;
  justify-content: space-between;
}

.input-field-bus, .input-field-phone {
  flex: 1;
}