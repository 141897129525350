@import '../../_styles/breakpoints';

.overview {
  > ul {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
    margin: 0 8rem 4.8rem 8rem;
    justify-items: center;

    @include breakpoint(desktop) {
      margin: 0 auto;
    }

    > li {
      padding-bottom: 4.8rem;
    }
  }

  > p {
    text-align: center;
    margin-bottom: 2rem;
  }
}
