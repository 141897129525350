.infobox {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 15px;
  padding: 1.6rem;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    width: 100%;

    > i {
      svg {
        path {
          fill: none;
        }
      }
    }
    &__date {
      display: flex;
      flex-direction: row;
      align-items: center;
      > h4 {
        margin-left: 2rem;
        white-space: initial;
        text-align: left;
      }
      &__split {
        display: flex;
        flex-direction: column;
      }
    }
  }

  &__info-not-visible {
    transform: rotate(180deg);
  }
}
