@import '../../../_styles/colors';

.timeline {
  height: auto;
  position: relative;
  display: flex;

  &__heading {
    margin-right: 2rem;
    padding-left: 2rem;
    > button {
      background-color: transparent;
      padding-bottom: 2.4rem;
    }

    &__visible {
      color: $primary-color;
    }
    
    border-left: 2px solid grey;
  }

  .dot {
    position: absolute;
    width: 1.4rem;
    height: 1.4rem;
    left: -0.6rem;
    border-radius: 50%;
    border: 0.15rem solid #9c9c9c;
    background-color: #9c9c9c;
    &__visible {
      border: 0.7rem solid $primary-color;
      background-color: $primary-color;
    }
  }

  .markdown {
    padding-top: 0.8rem;
    padding-bottom: 3rem;
    margin-left: 0.6rem;
  }
}
