@import '../../../_styles/colors';
@import '../../../_styles/constants';

.checkbox-wrapper {
  &.is-checked + label {
    color: $primary-color;
    accent-color: $primary-color;
  }

  &.is-checked {
    accent-color: $primary-color;
  }

  > label {
    margin-left: 1rem;
    color: $text-color;

    &.is-error {
      color: $red;
    }
  }

  input {
    height: 1.5rem;
    width: 1.5rem;
  }
}
