@import '../../_styles/breakpoints';
@import '../../_styles/colors';

.detail__description {
  width: 70rem;
  margin: 0 auto 6.4rem auto;

  @include breakpoint(desktop) {
    width: 100%;
    padding: 0 2rem 2rem 2rem;
  }

  &_body {
    padding: 1rem 0;
  }

  &__download {
    display: flex;
    width: 28.3rem;
    align-items: center;
    margin: 3rem 0;
    color: $primary-color;
    .icon {
      margin-left: 2rem;
      svg {
        path {
          fill: none;
        }
      }
    }
  }

  &_solicitation {
    &.primary {
      width: auto;
    }
    .icon {
      margin-left: 1rem;
    }
  }

  a {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    background: transparent;
  }
}

li h3 {
  color: $primary-color;
  font-size: 2rem;
  font-weight: bold;
  padding-bottom: 5rem;
  text-transform: uppercase;
}
