@import '../../_styles/breakpoints';
@import '../../_styles/colors';
.detail__description {
  width: 70rem;
  margin: 0 auto 6.4rem auto;
  font-size: 1.6rem;
  > h4 {
    color: $primary-color;
    font-size: 2.4rem;
  }
  > ul {
    padding: 2rem 0;
  }

  li > a {
    color: black;
    font-weight: normal;
    font-size: 1.4rem;
  }

  @include breakpoint(desktop) {
    width: 100%;
    padding: 0 2rem 2rem 2rem;
  }
}
