@import '../../_styles/colors';
@import '../../_styles/breakpoints';

.contact {
  color: $white;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.6) 100%),
    linear-gradient(0deg, rgba(244, 129, 32, 0.2), rgba(244, 129, 32, 0.2)), url('../../_assets/images/contact.jpg'), #ffffff;
  background-size: 150%;
  backdrop-filter: blur(2px);
  background-position: left center;
  padding: 8rem;

  @include breakpoint(tablet) {
    background-size: cover;
    background-position: center;
    padding: 2rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 4rem;

    @include breakpoint(tablet) {
      margin-bottom: 2rem;
    }
  }

  p {
    width: 40%;

    @include breakpoint(desktop) {
      width: 100%;
    }
  }
}
