@import '../../_styles/constants';
@import '../../_styles/colors';
@import '../../_styles/breakpoints';

.card {
  width: 35rem;
  height: 20rem;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: $border-radius;
  color: $white;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.15);
  
  @include breakpoint (desktop){
    width: 30rem;
  }

  span {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    border-radius: 0 $border-radius 0 $border-radius;
  }

  &__link {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
}
