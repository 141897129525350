@import '../_styles/colors';
@import '../_styles/buttons';
@import '../_styles/breakpoints';

.footer {
  background-color: $primary-color;
  color: $white;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 3rem 8rem;

  @include breakpoint(desktop) {
    gap: 2rem;
    flex-direction: column;
    text-align: center;
  }

  &__button {
    @include unstyled-button;
    background-color: $white;
    color: $primary-color;
    position: absolute;
    right: 10vw;
    top: -3rem;
    width: 6rem;
    height: 6rem;
    border-radius: 3rem;
    z-index: 1;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  a {
    text-decoration: underline;
  }

  h4 {
    margin-bottom: 0.8rem;
  }

  &__divider {
    height: auto;
    width: 2px;
    background-color: $white;

    @include breakpoint(desktop) {
      height: 2px;
      width: 10%;
      margin: 0 auto;
    }
  }

  &__copyright {
    align-self: flex-end;

    @include breakpoint(desktop) {
      align-self: center;
    }
  }
}
