@import '../../_styles/colors';

.markdown {
  padding-top: 3.4rem;
  padding-bottom: 6.4rem;
  line-height: 2.4rem;

  h1,
  h2,
  h3,
  h4 {
    color: $primary-color;
    font-size: 2.4rem;
    padding-bottom: 0.8rem;
  }

  p {
    font-size: 1.6rem;
  }

  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  a {
    color: $primary-color;
    text-decoration: underline;
  }
}
