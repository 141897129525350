@import '../../../_styles/colors';
@import '../../../_styles/constants';
@import '../../../_styles/breakpoints';

.content {
  display: flex;
  padding: 2rem 5rem;
  color: $grey-dark;
  align-items: flex-start;
  @include breakpoint(desktop) {
    margin-top: -15rem;
    flex-direction: column;
    align-items: center;
  }

  &_subdivision {
    display: flex;
    flex-direction: column;
    padding: 0 6.1rem;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    
    @include breakpoint(desktop) {
      width: 100%;
      padding: 0;
      z-index: 1;
    }

    &_description {
      padding: 2.4rem 0 3.4rem 0;
      font-size: 2rem;
      line-height: 3rem;
      text-align: center;
      .icon {
        svg{
          path{
            fill: none;
          }
        }
      }
      @include breakpoint(desktop) {
        font-size: 1.7rem;
      }

      @include breakpoint(tablet) {
        font-size: 1.4rem;
      }
    }

    a {
      color: $primary-color;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 3rem;
    }

    h2 {
      padding-top: 2rem;
      font-size: 3.2rem;
      line-height: 4.8rem;
      font-weight: 500;
      @include breakpoint(desktop) {
        font-size: 2.7rem;
      }
    }

    .link {
      svg {
        path {
          stroke: $primary-color;
        }
      }

      i.icon {
        margin-bottom: 0.6rem;
      }
    }
  }
}
