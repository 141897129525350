@import '../../_styles/breakpoints';
@import '../../_styles/colors';
.detail__description {
  width: 70rem;
  margin: 0 auto 6.4rem auto;

  > .markdown {
    > h2 {
      padding-bottom: 1.6rem;
    }

    a {
      color: currentColor;
      font-size: inherit;
      text-transform: lowercase;
    }
  }

  @include breakpoint(desktop) {
    width: 100%;
    padding: 0 2rem 2rem 2rem;
  }
}
