@import '../_styles/breakpoints';
@import '../_styles/colors';

.page-header {
  background-position: 70% 30%;
}

.calendar {
  margin-bottom: 5rem;
  .spinner-wrapper {
    width: 100%;
    .spinner {
      margin: 0 auto;
    }
  }
  &__details {
    width: 70rem;
    margin: 0 auto 6.4rem auto;

    @include breakpoint(desktop) {
      width: 100%;
      padding: 0 2rem 2rem 2rem;
    }

    > p {
      margin: 2rem 0;
      text-align: center;
    }

    .infobox {
      margin: 2rem 0;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.1rem;
      > button {
        background-color: transparent;
      }
      > h4 {
        color: $primary-color;
        text-transform: capitalize;
      }
    }
    &__element {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      margin-top: 1.4rem;
      > p {
        margin-left: 2.5rem;
        word-break: break-word;
      }
      .icon {
        svg{
          path{
            fill: none;
          }
        }
      }
      
      &__info {
        display: flex;
        margin-left: 2.5rem;
        flex-direction: column;
        > p {
          word-break: break-word;
        }
      }
    }
  }
}
