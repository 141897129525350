@import '../_styles/breakpoints';
@import '../_styles/colors';

.faq {
  &__detail__description {
    width: 70rem;
    margin: 0 auto 6.4rem auto;
    padding-bottom: 2rem;

    .infobox:not(first-of-type) {
      margin-top: 2rem;
    }

    p {
      margin-left: 2.5rem;
      color: $grey-dark;
    }

    @include breakpoint(desktop) {
      width: 100%;
      padding: 0 2rem 2rem 2rem;
    }
  }
}
