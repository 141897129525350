@import '../../_styles/breakpoints';
@import '../../_styles/colors';
.contact {
  &__detail__description {
  width: 70rem;
  margin: 0 auto 6.4rem auto;
  font-size: 1.6rem;
  > h4 {
    color: $primary-color;
    font-size: 2.4rem;
  }
  > ul {
    padding: 2rem 0;
  }
  > a {
    display: flex;
    width: 21.5rem;
    margin: 2rem 0;
    background-color: $primary-color;
    border-radius: 8px;
    align-items: center;
    padding: 1rem;
  }

  li > a {
    color: black;
    font-weight: normal;
    font-size: 1.4rem;
  }

  table {
    margin-top: 2rem;
    width: 100%;
    tr {
      display: flex;
      color: #666666;
      padding: 0.4rem 0.8rem;
      justify-content: space-between;
    }
    tr:nth-child(odd) {
      background: rgba(244, 129, 32, 0.2);
    }
  }

  @include breakpoint(desktop) {
    width: 100%;
    padding: 0 2rem 2rem 2rem;
  }
}
}