@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap');

$font-size: 2rem;
$font-family: 'Readex Pro', sans-serif;

$border-radius: 0.8rem;
$disabled-opacity: 0.75;
$navbar-height: 10rem;
$input-height: 4rem;

$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;
