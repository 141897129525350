@import '../_styles/breakpoints';
@import '../_styles/colors';

.disclaimer {
  width: 70rem;
  margin: 0 auto;

  > h3 {
    color: $primary-color;
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 3rem;
    text-transform: uppercase;
  }
  > p {
    color: $grey-dark;
    margin-bottom: 0.6rem;
    padding-bottom: 2rem;
    font-size: 1.6rem;
  }
}
