@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.home {
  &_header {
    display: flex;
    color: $white;
    flex-direction: column;
    background-image: url(../_assets/images/intro-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: 110vh;
    margin-top: -$navbar-height;
    position: relative;

    &_description {
      display: flex;
      position: absolute;
      padding: 0 13.7rem;
      top: 26.27%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      @include breakpoint(monitor) {
        padding: 0 4rem;
      }
      @include breakpoint(desktop) {
        padding: 0 2rem;
      }
      @include breakpoint(tablet) {
        padding: 0 1rem;
      }

      > p {
        color: $white;
        font-size: 2rem;
        width: 60rem;
        padding: 5rem 0;

        @include breakpoint(tablet) {
          font-size: 1.6rem;
          width: 100%;
        }
      }

      > h1 {
        font-weight: bold;
        font-size: 8rem;
        @include breakpoint(monitor) {
          font-size: 4rem;
        }
      }
      &_button {
        font-size: 2rem;
        align-items: center;
        font-weight: normal;
        line-height: 3rem;
        text-align: center;
        padding: 1rem 2rem;
        text-transform: uppercase;
        border-radius: 0.8rem;
      }
    }
  }
}
