@import '../../_styles/colors';
@import '../../_styles/constants';

.input-wrapper {
  position: relative;

  .input-wrapper-label:not(:last-child) {
    display: flex;
    align-items: center;
    min-height: $input-icon-height;
    margin-bottom: $input-label-bottom-margin;
    font-size: medium;
    color: $form-primary-color;

    > :not(:first-child) {
      margin-left: 0.75rem;
    }

    i,
    svg {
      width: $input-icon-height;
      height: $input-icon-height;
    }
  }
}

.input-field-wrapper {
  margin-bottom: 2rem;

}

.ui.input,
.ui.selection.dropdown {
  min-width: 100%;
}
