@import '../../_styles/colors';
@import '../../_styles/constants';

$padding: 1.1rem 2.4rem;
$icon-height: 2rem;

button {
  font-size: 1.4rem;
  font-family: $font-family;
  border: none;
  cursor: pointer;
  text-transform: uppercase;

  &:disabled {  
    opacity: $disabled-opacity;
    pointer-events: none;
  }
}

.button {
  &.primary {
    color: $white;
    background-color: $primary-color;
  }
  &.negative {
    color: $primary-color;
    background-color: $white;
  }
}

.as-text {
  background: transparent;

  &.primary {
    color: $primary-color;
  }
  &.negative {
    color: $white;
  }
}

.button,
.as-text {
  border-radius: $border-radius;
  padding: $padding;
  font-weight: bold;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: $input-height;
  position: relative;

  > :not(:first-child) {
    margin-left: 0.8rem;
  }

  .icon-external {
    margin-left: 1rem;
  }

  &.loading {
    pointer-events: none;
    color: transparent;
    &:hover,
    &:focus {
      color: transparent;
    }

    .spinner-wrapper {
      margin: 0;
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .icon,
  svg {
    width: $icon-height;
    height: $icon-height;
  }
}
