$primary-color: #eb6b00;
$secondary-color: #d6680b;
$text-color: #181616;
$placeholder-color: #b8bcbf;
$disabled-background-color: #f3f3f3;
$error-background-color: #fff6f6;
$error-border-color: #e0b4b4;
$error-text-color: #9f3a38;
$error-placeholder-color: #e7bdbc;
$input-border-color: rgba(34, 36, 38, 0.25);

$shadow: rgba(34, 36, 38, 0.15);

$grey: #999fa3;
$grey-dark: #666666;
$grey-light: #ebeced;
$grey-lighter: #f9fafb;
$red: #e45f55;
$red-light: #fceaea;
$blue: #3080cf;
$white: #ffffff;

$form-primary-color: #434343;
$form-secondary-color: #272727;
$form-bar: #d2d0ce;
$form-box-shadow: rgba(0, 0, 0, 0.25);
