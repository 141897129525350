@import './_styles/breakpoints';
@import './_styles/colors';
@import './_styles/constants';
@import './_styles/reset';

html {
  // This value defines the value of '1rem'
  font-size: 10px;
}

html,
body {
  height: auto;
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

h1 {
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-size: 5.6rem;
  font-weight: 700;
  line-height: 1;
}

h3 {
  font-size: 4.8rem;
  font-weight: 600;
  line-height: 1;
}

body {
  font-size: $font-size;
}

a {
  text-decoration: none;

  &.disabled {
    pointer-events: none;
    opacity: $disabled-opacity;
  }
}
