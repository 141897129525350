@import '../../_styles/breakpoints';
@import '../../_styles/colors';

.page-header {
  background-position: 70% 30%;
}

.detail__description {
  width: 120rem;
  margin: 0 auto 6.4rem auto;

  @include breakpoint(desktop) {
    width: 100%;
    padding: 0 2rem 2rem 2rem;
  }

  > p {
    margin: 2rem 0;
  }

  .infobox {
    margin: 2rem 0;
  }
  > h3 {
    color: $primary-color;
  }

  .employees {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem;
    margin-top: 2rem;

    @include breakpoint(tablet) {
      grid-template-columns: 100%;
    }

    &__single_employee {
      display: flex;
      align-items: flex-start;

      img {
        width: 9rem;
        height: 9rem;
        border-radius: 5rem;
        margin-right: 1.6rem;
        object-fit: cover;
      }
    }
  }
}
