@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';


.page-header {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  text-align: center;
  padding: 22rem 0 18rem;
  top: -$navbar-height;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
> h2 {
  @include breakpoint(desktop) {
    font-size: 4.6rem ;

  }
}
  > a {
    margin: 5rem;
    @include breakpoint(desktop) {
      margin: 0;

    }
    i {
      svg {
        transform: rotate(-90deg);
      }
    }
  }

  &__link {
    position: absolute;
    top: 17.1rem;
    left: 8rem;
  }
}
